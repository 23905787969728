.field-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.field {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 4rem;

    >* {
        margin: 0;
        padding: 0;
    }

    &.id {
        width: 4rem;
        max-width: 4rem;
        justify-content: center;
    }

    &.question {
        flex-basis: calc(60% - 4rem - 9px);
    }

    &.answer {
        flex-basis: 25%;

        >p {
            display: block;
            width: 100%;

            &.answer {
                font-weight: $fw-bold;
            }

            &.comment {
                color: $c-gray;
                font-size: $fs-small;
            }
        }
    }

    &.recommendation {
        justify-content: flex-end;
    }

    &.score {
        text-align: right;
        justify-content: flex-end;
        min-width: 6.5rem;
        flex-grow: initial;
    }

    &--score {
        flex-basis: 15%;
        justify-content: flex-end;

        >* {
            line-height: 1;
        }

        .score-display {
            @extend p;
            font-weight: $fw-bold;
            margin: 0 0 0 1rem;
            padding: 0;
        }
    }

    &.comment {
        flex-basis: calc(60% - 9px);

        input {
            padding: 0.5rem;
        }
    }

    &.tags {
        flex-basis: 40%;
    }
}