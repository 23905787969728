.form {
    &-group {
        margin-bottom: 2rem;
    }

    &-control {
        &::placeholder {
            color: lighten($c-gray, 25%);
        }
    }
}

.check-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.custom {
    &-control {
        margin: 0;
        padding: 0;

        &-inline {
            margin-right: 0;
            width: 100%;
        }

        &-input {
            &:checked {
                z-index: 9;

                &~.custom-control-label {
                    border-color: $c-brand !important;
                    background-color: $c-brand;
                    color: $c-white !important;
                    font-weight: $fw-bold;

                    &:before {
                        // background-color: $c-brand;
                        // border-color: $c-brand;
                    }
                }
            }

            &:focus {
                &~.custom-control-label::before {
                    // box-shadow: 0 0 0 3px rgba(0, 168, 168, 0.25);
                }
            }
        }

        &.form-check {

            &--yes {
                label.custom-control-label {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
            }

            &--no {
                margin-left: -1px;

                label.custom-control-label {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
            }

            label.custom-control-label {
                border: 1px solid $c-brand;
                color: $c-brand;
                border-radius: 4px;
                padding: 0.5rem 1rem 0.5rem 1rem;
                margin-right: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                cursor: pointer;
                transition: 0.2s ease-out;

                &:before,
                &:after {
                    display: none;
                }

                // >span {
                //     position: relative;
                //     display: block;
                //     width: 100%;
                //     height: 100%;

                // &:before,
                // &:after {
                //     content: "";
                //     left: 1.2rem;
                //     top: 50%;
                //     transform: translate(-50%, -50%);
                // }
                // }


            }
        }
    }
}

.custom-control-input:focus~.custom-control-label::before input[type='file'] {
    margin-left: 1rem;
    width: 100%;
}

form {
    .honey {
        position: absolute;
        left: -9999px;
    }
}



// SELECT FIELD
.field {

    .select-container {
        width: 100%;
        position: relative;

        &:after {
            position: absolute;
            content: "›";
            top: 50%;
            right: 1rem;
            transform: translateY(-50%) rotate(90deg);
            font-size: $fs-x-large;
            color: $c-white;
        }

        select {
            appearance: none;
            padding: 0.5rem 2rem 0.5rem 1rem;
            color: $c-white;
            font-weight: $fw-bold;
            border-color: $c-brand;
            border-radius: 4px;
            background-color: $c-brand;

            &:focus-visible {
                outline-color: $c-brand-light;
            }
        }
    }
}