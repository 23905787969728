nav {
    visibility: hidden;
    z-index: 33;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 14rem;
    
    @include media-breakpoint-up (lg) {
        padding-top: 0;
        position: relative;
        visibility: visible !important;
        opacity: 1 !important;
        top: 0;
        left: 0;
        transform: none;
    }

    &.active {
        // height: auto;
        overflow: hidden;
    }

    ul {
        // margin: auto;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up (lg) {
            flex-direction: row;
        }
    }

    li {
        position: relative;
        text-align: center;

        @include media-breakpoint-up (lg) {
            text-align: left;
        }
    }

    .submenu {
        padding: 0 2rem;
        z-index: 99;
        background-color: $c-white;
        
        @include media-breakpoint-up (lg) {
            padding: 1rem 2rem;
            position: absolute;
            box-shadow: 0 12px 12px -4px rgba(0, 0, 0, 0.2);
        }

        a {
            padding: 1rem 0;
            font-weight: $fw-normal;
            font-size: $fs-base;
            white-space: nowrap;
        }
        
        &.visible {
            display: block;
        }
    }
    
    .subsubmenu {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
        
        @include media-breakpoint-up (lg) {
            padding-left: 2rem;
            align-items: flex-start;
        }

        li:first-child a {
            padding-top: 0;
        }
        
        a {
            padding: 0.5rem 0;
            font-size: $fs-small;
        }
    }
}

.btn-mobile-nav {
    position: absolute;
    z-index: 99999;
    top: 2rem;
    right: 1rem;
    width: 64px;
    height: 64px;
    padding: 0;
    display: block;
    cursor: pointer;
    overflow: hidden;
    @include transition($t-short $ease-default);

    @include media-breakpoint-up(md) {
        top: 2rem;
        right: 3rem;
        transform: scale(1);
    }

    @include media-breakpoint-up(lg) {
        top: 2rem;
        right: 3rem;
    }

    @include media-breakpoint-up(lg) {
        // display: none;
        visibility: hidden;
    }

    &.active {
        .btn-nav-bar {
            background-color: $c-brand;
        }
    }
}

.mobile-bg {
    position: fixed;
    z-index: 22;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $c-white;
    visibility: hidden;

    @include media-breakpoint-up (lg) {
        visibility: hidden !important;
    }
}

body {
    &.mobile-nav--active {
        // overflow: hidden;
    }
}