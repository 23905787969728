.survey-result {

    &+.survey-result {
        margin-top: 1.5rem;
    }

    &__grid {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;

        &.quarter {
            .field {
                flex-basis: calc(25% - 2px);
            }
        }

        &.third {
            .field {
                flex-basis: calc(33.3% - 2px);
            }
        }

        &.half {
            .field {
                flex-basis: calc(50% - 2px);
            }
        }

        &.full {
            .field {
                flex-basis: 100%;
                margin-right: 0;

                &__label {
                    flex-basis: 25%;
                }

                &__value {
                    flex-basis: 75%;
                }
            }
        }
    }

}
.field {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    background: $c-white;
    border: 1px solid $c-light-gray;
    padding: 0.5rem 1rem;
    margin: 0 -3px -3px 0;

    &__label {
        flex-basis: 25%;

        p {
            margin: 0;

            &.comment {
                font-size: $fs-small;
                color: $c-gray;
            }
        }
    }

    &__value {
        flex-basis: 75%;
        font-weight: $fw-bold;

        &--score {
            font-size: $fs-large;
            text-align: center;
        }

        &--single {
            flex-basis: 100%;
        }
    }
    
    &--question {
        flex-basis: 100%;
        background-color: transparent;
    }
}