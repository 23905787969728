.alert {
    padding: 1.5rem;
    margin: 0.5rem auto;
    width: auto;

    @include media-breakpoint-up (lg) {
        padding: 1.5rem 2rem;
        width: 100%;
        margin: 1.5rem auto;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}