#privacy {
  .cc {
    &-window {
      background-color: $c-black;
      color: $c-white;

      * {
        font-size: $fs-base;
        font-weight: $fw-normal;
      }

      a {
        color: $c-brand;
      }
    }

    &-message {
      padding: 0.5em 0.8em;
    }

    &-compliance {
      background-color: $c-brand;

      a {
        color: $c-black;
        border: none;
      }
    }

    &-revoke {
      bottom: 0;
      right: 0;
      background-color: $c-black;
      color: $c-white;
      padding: 0.5em 0.8em;
      line-height: 1;
      font-size: $fs-small;
    }
  }
}