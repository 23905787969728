.login {
    background-color: $c-white;
    padding: 4rem 6rem;
}

// #kerli81-securedpages-loginform {
//     .login-page {
//         .form {
//             box-shadow:
//                 0 0.3px 2.2px rgba(0, 0, 0, 0.02),
//                 0 0.7px 5.3px rgba(0, 0, 0, 0.028),
//                 0 1.3px 10px rgba(0, 0, 0, 0.035),
//                 0 2.2px 17.9px rgba(0, 0, 0, 0.042),
//                 0 4.2px 33.4px rgba(0, 0, 0, 0.05),
//                 0 10px 80px rgba(0, 0, 0, 0.07);

//             input, button {
//                 font-size: $fs-base;
//                 font-family: inherit;
//             }

//             input {
//                 background: $c-brand-light;
//                 color: $c-brand;
//                 -webkit-text-fill-color: $c-black;
//                 -webkit-box-shadow: 0 0 0px 1000px $c-brand-light inset;
//             }

//             button {
//                 text-transform: capitalize;
//                 font-weight: $fw-bold;
//                 background: $c-gray;
//                 transition: 0.2s ease-out;

//                 &:hover {
//                     background: $c-brand;
//                 }
//             }

//             .warning-text {
//                 font-size: $fs-small;
//                 padding: 0;
//                 color: $c-black;
//                 margin: 0 0 1rem 0;
//                 border: none;
//                 background: none;

//                 a {
//                     color: $c-brand;
//                 }
//             }
//         }
//     }
// }