.questions {

    &+.questions {
        margin-top: 3rem;
    }

    .id {
        p {
            margin: 0;
            text-align: center;
        }
    }

    .question {

        p {
            padding-right: 1.5rem;
            display: inline-block;
            position: relative;
            margin: 0;
        }

        .info {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin-left: 0.5rem;
            line-height: 1;
            transition: 0.2s ease-out;
            cursor: pointer;
            will-change: transform;

            &:hover {
                color: $c-brand;
            }
        }
    }

    select {
        width: 100%;
    }
}