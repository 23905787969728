.btn {
    &-primary {
        background-color: $c-brand;
        border-color: $c-brand;
        border: none !important;
        font-size: $fs-base;
        font-weight: $fw-bold;
        border-radius: 2px;

        &:hover {
            background-color: darken($c-brand, 3%);
        }

        &:active,
        &:focus {
            background-color: darken($c-brand, 3%) !important;
            box-shadow: 0 0 0 0.2rem rgba(0, 153, 153, 0.5) !important;
        }
    }

    &-lg {
        padding: 1em 2em;
    }
}