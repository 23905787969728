.header {
    padding: 1.5rem ;
    display: flex;
    flex-direction: column;
    background-color: $c-white;
    @extend .container;

    @include media-breakpoint-up (lg) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;

    }
}

.brand {
    z-index: 34;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media-breakpoint-up (lg) {
        // align-items: flex-end;
        margin-bottom: 0;
    }
}

.subtitle {
    min-width: 50%;

    >* {
        margin: 0;
        color: $c-black;
        font-size: $fs-small;
        max-width: 50%;
        margin-left: 1rem;
        margin-bottom: 0;

        @include media-breakpoint-up (md) {
            margin-left: 2rem;
            font-size: $fs-base;
            max-width: none;

        }
    }

    strong {
        color: $c-brand;
        font-weight: $fw-normal;
    }
}

.logo {
    display: block;

    // @include media-breakpoint-up (lg) {
    //     margin-right: 3em;
    // }

    >img {
        width: 5rem;

        @include media-breakpoint-up (lg) {
            width: 6rem;
        }
    }
}

nav {

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

        &.submenu {
            display: none;
        }
    }

    a {
        color: $c-black;
        font-weight: $fw-bold;
        display: block;
        padding: 1em 2em;

        &.active {
            color: $c-brand;
        }

        &.highlight {
            background-color: $c-brand;
            color: $c-white;
            border-radius: 2px;
            margin-top: 1rem;

            @include media-breakpoint-up (lg) {
                margin-left: 1em;
                margin-top: 0;
            }
        }
    }
}