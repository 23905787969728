.score:not(.field) {
    display: flex;
    flex-direction: row;
    align-items: center;

    * {
        @extend .h3;
        margin-left: 0.5rem;
        opacity: 0.33;
        margin-bottom: 0;
    }

    .reached {
        opacity: 1;
    }

    // Variants

    &.small {
        * {
            @extend .h5;
            margin-left: 0.5rem;
        }
    }

    &.large {
        * {
            @extend .h1;
            margin-left: 0.5rem;
        }
    }

    &.total {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        >span {
            @extend .h2;
            padding: 0;
            margin: 0 0 0 0.5rem;
        }
    }
}

.score-display {
    font-weight: $fw-bold;
    text-align: right;
}