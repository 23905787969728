.applicants-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__link {
        position: relative;
        color: inherit;
        text-decoration: none;
        display: block;
        padding: 0.75rem 1.5rem;
        background-color: $c-white;
        transition: 0.2s ease-out;
        will-change: box-shadow transform;

        +.applicants-list__link {
            margin-top: 2px;
        }

        &:hover {
            box-shadow: 0 1px 3px -1px rgba(0,0,0,0.1);
            .applicants-list {
                &__icon {
                    opacity: 1;
                    transform: translate(0, -50%);
                }
            }
        }
    }

    &__name {
        font-weight: $fw-bold;
    }

    &__mail {
        color: $c-gray;
    }

    &__icon {
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transition: transform;
        will-change: transform;
        transition: 0.2s ease-out;
        transform: translate(-25%, -50%);
    }
}