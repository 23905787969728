.nav {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.5rem;

    &-tabs {
        border-color: $c-brand;
        margin-bottom: 3rem;
        border-bottom: 1px solid $c-brand;
    }

    &-link {
        border-radius: 2px !important;
        border: none !important;
        font-size: $fs-base;
        padding: 0.6rem 1.2rem;
        color: $c-black;
        border-bottom: 3px solid transparent !important;

        &:hover {
            border-color: transparent !important;
        }

        &.active {
            color: $c-brand !important;
            background-color: transparent !important;
            border-color: $c-brand !important;
        }
    }
}