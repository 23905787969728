table.table {
    border: 1px solid;
    border-color: $c-light-gray;

    th {
        padding: 1rem;
        border-bottom-width: 1px;
        font-size: $fs-small;
        text-transform: uppercase;

        span {
            display: block;
        }

    }

    tr {
        &:hover {
            background-color: transparent !important;
        }

        cursor: pointer;

        &.odd {
            background-color: $c-light;
        }
    }

    td {
        padding: 1rem;
        transition: 0.2s ease-out;
        filter: brightness(1.05);

        &.empty {
            color: $c-gray;
        }
    }

    th,
    td {
        border-left: 1px solid;
        border-color: $c-light-gray;

        a {
            color: $c-black;
        }


    }

}


// Applicants Table

#applicants-list {

    tr {

        &:hover {
            background-color: $c-brand-light !important;

            a {
                color: $c-brand;
            }
        }
    }

}


// Summary Table

#summary-table {

    tr {

        &:hover {
            background-color: $c-light !important;
        }
    }

    td {
        &:hover {
            filter: brightness(1.1);

            a {
                color: $c-brand;
            }
        }
    }

    th,
    td {

        font-size: $fs-large;
        * {
            font-size: $fs-large;
        }

        &.rank {
            max-width: 3rem;
        }

        &.value {
            width: 7%;
        }

        &.colored {
            border-color: $c-white;
        }

        .subline {
            text-transform: uppercase;
            font-weight: $fw-bold;
            font-size: $fs-small;
        }
        
        .name {
            font-weight: $fw-normal;
            text-transform: none;            
            font-size: $fs-small;
        }

        &.bold {
            font-weight: $fw-bold;
        }

        &.small {
            font-size: $fs-small;
        }

        .badge {
            font-size: $fs-small;
        }
    }

    .comment {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 8rem;
        overflow: hidden;

        &.active {
            overflow: visible;
            white-space: normal;
            text-overflow: normal;
        }
    }
}