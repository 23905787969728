main {
    @extend .container;
    margin: 3px auto;
    padding: 1.5rem;
    background: $c-white;
    
    @include media-breakpoint-up (lg) {
        padding: 3rem;        
    }

    > h1, h2, h3 {
        margin-top: 0;
    }
}

body {

    // Full width layout
    &.fullsize {

        main,
        header,
        footer {
            max-width: none;
        }
    }
}