.gallery {
    margin: 3rem 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0.5rem;
    }

    img {
        width: 100%;
    }
}