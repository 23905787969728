.summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3em;

    &__row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $c-gray;
        padding: 0.5em 0;

        &:nth-last-child(2) {
            border-bottom: none;
        }
    }

    &__question {
        flex-basis: 70%;
    }

    &__points {
        flex-basis: 30%;
        font-weight: $fw-bold;
        text-align: right;
    }

    &__result {
        border-bottom: none;
        border-top: 3px double $c-gray;
    }

    &__total {
        border: none;

        .summary__row {
            border: none;
        }
    }


}