//== Typography
//

// Font Family
$ff: 	                  open-sans, sans-serif;
$font-family-sans-serif:  $ff !default;
$font-family-base:        $ff !default;

// Font Weight
$fw-normal:               400;
$fw-bold:                 700;

// Letter Spacing
$ls-micro:                0.2em;
$ls-button:               0.1em;
$ls-large:                0.1em;
$ls-base:                 0.06em;
$ls-copy:                 0.01em;
$ls-data:                 0;
$ls-h1:                   0;
$ls-h2:                   0;
$ls-h3:                   0;

// Font Size
$fs-micro:                11px !default;
$fs-tiny:                 12px !default;
$fs-small:                14px !default;
$fs-base:                 16px !default;
$fs-large:                18px !default;
$fs-x-large:              24px !default;    
$fs-xl-large:             36px !default;
$fs-jumbo:                48px !default;
$fs-max:                  64px !default;
$font-size-h1:            $fs-jumbo !default;
$font-size-h2:            $fs-xl-large !default;
$font-size-h3:            $fs-x-large !default;
$font-size-h4:            $fs-large !default;
$font-size-h5:            $fs-base !default;
$font-size-h6:            $fs-small !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.6  !default; // 20/14
$line-height-headlines:   1.5;
$line-height-mobile:      1.6;
$line-height-narrow:      1.4;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($fs-base * $line-height-base)) !default; // ~20px



//== Colors
//

$c-brand:                 #00a8a8;
$c-brand-light:           #d3e9e9;
$c-black:                 #303030;
$c-gray:                  #666d6d;
$c-light-gray:            #e5e5e5;
$c-light:                 #f6f9f9;
$c-white:                 #ffffff;
$c-warn:                  #a83500;



//== Animation
//

// Time and Easing
// $t-short $ease-default:            .2s ease-out;
// $t-short $ease-default:              .3s ease-out;
// $t-medium $ease-default:             .6s ease-out;
// $t-long $ease-default:               .9s ease-out;



//== Transitions
//

// Timing
$t-x-short              : 0.1s;
$t-short                : 0.2s;
$t-medium               : 0.3s;
$t-long                 : 0.6s;


// EASING

// Cubic
$ease-in-cubic          : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-out-cubic         : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-in-out-cubic      : cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
$ease-in-circ           : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-out-circ          : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-in-out-circ       : cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
$ease-in-expo           : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-out-expo          : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-in-out-expo       : cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
$ease-in-quad           : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-out-quad          : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-in-out-quad       : cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
$ease-in-quart          : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-out-quart         : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-in-out-quart      : cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
$ease-in-quint          : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-out-quint         : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-in-out-quint      : cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
$ease-in-sine           : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-out-sine          : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-in-out-sine       : cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
$ease-in-back           : cubic-bezier(0.600, -0.280, 0.735, 0.045);
$ease-out-back          : cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ease-in-out-back       : cubic-bezier(0.680, -0.550, 0.265, 1.550);

$ease-default           : $ease-out-quad;

