.survey {
    &-form {
        label {
            span {
                vertical-align: middle;
            }
        }

        .info-icon {
            vertical-align: 3px;
            font-size: 1.2rem;
            color: $c-black;
            cursor: pointer;
            transition: 0.2s ease-out;

            &:hover {
                color: $c-brand;
            }
        }

    }

    &-group {
        margin-bottom: 2rem;
    }
}

.badge {
    display: none;

    &.active {
        display: inline-block;
    }
}