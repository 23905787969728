body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c-black;
    font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: 1.4;
    display: block;
}

h1,
.h1 {
    margin-bottom: $font-size-h1;
}

h2,
.h2 {
    margin-bottom: $font-size-h2;
    margin-top: $font-size-h2;
}

h3,
.h3 {
    margin-bottom: $font-size-h3;
}

h4,
.h4 {
    margin-bottom: $font-size-h4;
}

a {
    transition: $t-short $ease-default;

    &:hover {
        text-decoration: none;
        color: $c-brand;
    }

    &.back {
        font-size: $fs-small;
        font-weight: $fw-bold;

        span.icon {
            margin-right: 0.5rem;
            transform: rotate(180deg);
        }
    }
}

hr {
    margin: 2.5em 0 4em 0;
}

s {
    text-decoration: none;
    color: $c-brand;
}

.number {
    color: $c-brand;
}

// LINKS

a {
    color: $c-brand;
    &.back {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 0;
        color: $c-black;
        font-weight: $fw-bold;
        width: auto;

        >span:first-child {
            margin-right: 0.25rem;
        }

        &:hover {
            color: $c-brand
        }
    }
}