// Headline
.headline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;


    // Variants

    &.small {
        margin-bottom: 1rem;

        h1,
        h2,
        h3,
        h4,
        h5 {
            @extend .h5;
        }
    }

    &.large {

        h1,
        h2,
        h3,
        h4,
        h5 {
            @extend .h1;
            margin-bottom: 2rem;
        }
    }
}