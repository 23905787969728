.member {
    display: flex;
    width: 100%;
    background: $c-white;
    flex-direction: column;
    padding: 2rem;

    @include media-breakpoint-up (lg) {
        align-items: center;
        flex-direction: row;
        padding-right: 2rem;
        padding: 0 2rem 0 0;
    }

    &+.member {
        margin-top: 1rem;
    }

    &__image {
        max-width: 10rem;
        margin-bottom: 2rem;
        padding: 1rem;

        @include media-breakpoint-up (lg) {
            margin-right: 1rem;
            margin-bottom: 0;
        }

        &-placeholder {
            margin-right: 2rem;
            background: $c-light-gray;
            min-width: 10rem;
            min-height: 10rem;
        }
    }

    &__name {
        margin-bottom: 0;
    }

    &__lbl {
        margin: 0 0 2rem 0;

        @include media-breakpoint-up (lg) {
            flex-basis: 40%;
            margin: 0 2rem 0 0;
            margin-right: 2rem;
        }
    }

    &__mail {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $c-brand;

        a {
            margin-left: 0.5rem;
        }
    }

    &__details {
        flex-basis: 60%;
        padding: 1rem 0;

        >*:last-child {
            margin-bottom: 0;
        }
    }
}