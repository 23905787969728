.footer {
    @extend .container;
    background: $c-white;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    
    @include media-breakpoint-up (lg) {
        align-items: flex-end;
        padding: 0 3rem;        
        flex-direction: row;
    }

    &__links {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

        li:first-child() {
            a {
                padding-left: 0;
            }
        }

        li:last-child() {
            a {
                padding-right: 0;
            }
        }
    }
    
    a {
        font-weight: $fw-bold;
        color: $c-black;
        font-size: $fs-small;
        padding: 1rem;
        display: inline-block;

        &:hover {
            color: $c-brand;
        }

        + a {
            margin-left: 2rem;
        }
    }

}