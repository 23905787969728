.applicant-data {

    &__grid {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;

        &.quarter {
            .field {
                flex-basis: calc(25% - 2px);
            }
        }

        &.third {
            .field {
                flex-basis: calc(33.3% - 2px);
            }
        }

        &.half {
            .field {
                flex-basis: calc(50% - 2px);
            }
        }

        &.full {
            .field {
                flex-basis: 100%;
                margin-right: 0;

                &__label {
                    flex-basis: 25%;
                }

                &__value {
                    flex-basis: 75%;
                }
            }
        }
    }

    .field {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        height: auto;
        min-height: 3rem;

        &__label {
            flex-basis: 50%;

            p {
                margin: 0;

                &.comment {
                    font-size: $fs-small;
                    color: $c-gray;
                }
            }
        }

        &__value {
            flex-basis: 50%;
            font-weight: $fw-bold;

            &--score {
                font-size: $fs-large;
                text-align: center;
            }

            &--single {
                flex-basis: 100%;
            }
        }

        &--answer {
            .field {
                &__label {
                    flex-basis: 80%;
                }

                &__value {
                    flex-basis: 10%;
                    font-weight: $fw-bold;
                }
            }
        }

        &--question {
            flex-basis: 100%;
        }
    }
}