.group {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;        
    
    // &+.group {
    // }

    &:last-of-type{
        margin-bottom: 0 !important;
    }

    &.reverse {
        flex-direction: column-reverse;
    }

    & + .score {
        margin-top: -4rem;
    }

}